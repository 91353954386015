import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import "../assets/css/style.scss";
import { SearchQuestions } from "../../../api/actions/searchQuestions";
import Post from "../questionPost/post.js";
import { useDispatch, useSelector } from "react-redux";
import { addAllQuestions } from "../../../state/app.js";
import Skeleton from "react-loading-skeleton";

export default function SearchQuestion({ location }) {
  var search_querry;

  if (!!location.search.split("?search=")[1]) {
    search_querry = location.search.split("?search=")[1].replace(/%20/g, " ");
  }

  const dispatch = useDispatch();

  const [all_questions, setall_questions] = useState([]);

  const [loading, isloading] = useState(true);
  const all_Questions_redux = useSelector((state) => state.app.allquestions);

  useEffect(() => {
    setall_questions(all_Questions_redux);
  }, [all_Questions_redux]);

  useEffect(() => {
    (async () => {
      isloading(true);
      const answer = await SearchQuestions(search_querry);

      isloading(false);
      if (answer) {
        dispatch(addAllQuestions(answer));
      }
    })();
  }, [search_querry]);

  return (
    <>
      <div className="qustionsPage">
        <div className="content">
          <Row className="swap-mobile-flex">
            <Col xs={12} sm={9}>
              <div className="post">
                <div className="configuration" style={{ padding: "40px 0px" }}>
                  <h4>Results for “{search_querry}?”</h4>
                </div>
                {all_questions.length == 0 && loading === false
                  ? "No data found."
                  : null}
                {loading === true ? (
                  <Skeleton count={5} />
                ) : (
                  all_questions.map((data) => {
                    var alldecipline = [];

                    if (data.best_answer != null) {
                      data.best_answer.created_by.degrees.map((x, y) => {
                        if (y <= 2) {
                          alldecipline.push(x.description);
                        }
                      });
                    } else {
                      if (data.answers.length > 0) {
                        data.answers[0].created_by.degrees.map((x, y) => {
                          if (y <= 2) {
                            alldecipline.push(x.description);
                          }
                        });
                      }
                    }
                    return (
                      <Post
                        title={data.description}
                        img={
                          data.best_answer != null
                            ? data.best_answer.created_by.url_avatar
                            : data.answers.length > 0
                            ? data.answers[0].created_by.url_avatar
                            : null
                        }
                        name={
                          data.best_answer != null
                            ? data.best_answer.created_by.first_name +
                              " " +
                              data.best_answer.created_by.last_name
                            : data.answers.length > 0
                            ? data.answers[0].created_by.first_name +
                              " " +
                              data.answers[0].created_by.last_name
                            : null
                        }
                        designation={alldecipline.toString()}
                        answer={
                          data.best_answer != null
                            ? data.best_answer.description
                            : data.answers.length > 0
                            ? data.answers[0].description
                            : null
                        }
                        tag={
                          data.subtopics && data.subtopics.length > 0
                            ? data.subtopics[0].url
                            : null
                        }
                        tag_title={
                          data.subtopics && data.subtopics.length > 0
                            ? data.subtopics[0].description
                            : null
                        }
                        answers={data.answers_counter}
                        questionid={data.id}
                      />
                    );
                  })
                )}
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}
